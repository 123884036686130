import classNames from 'classnames'
import React, { Fragment, memo, useCallback, useMemo, useState } from 'react'
import update from 'immutability-helper'
import {
  branch,
  compose,
  renameProp,
  renameProps,
  renderComponent
} from 'recompose'
import styled from 'styled-components'
import { I } from '../../common/globals'
import { FEEDBACKS } from '../../common/path'
import {
  customDefaultOptions,
  emptyObject,
  feedbackActions,
  feedbackFontColors,
  feedbackFontFamilies,
  feedbackFontSizes,
  feedbackFontStyles,
  feedbackOutgoingPosition
} from '../../common/constants'
import { AEO_MANUAL } from '../../common/v5/chatbotConstants'
import {
  DEFAULT_EXTERNAL_URL_PREFIX,
  IMAGE_POSITION_TOP,
  TXT_ALREADY_REGISTERED,
  TXT_AREA_REPLIES,
  TXT_BROWSE,
  TXT_CONTACT_BUTTON,
  TXT_CONTACT_BY_EMAIL,
  TXT_CONTACT_BY_PHONE,
  TXT_CONTACT_ME,
  TXT_EXTERNAL_URL,
  TXT_FEEDBACK_NAME,
  TXT_FEEDBACK_SETTINGS,
  TXT_FEEDBACK_RESPONSE,
  TXT_FONT,
  TXT_FONT_COLOR,
  TXT_FONT_SIZE,
  TXT_FONT_STYLE,
  TXT_IDENTIFIER,
  TXT_IMAGE_POSITION,
  TXT_METER,
  TXT_NUMBER_OF_OPTIONS,
  TXT_OPTION1,
  TXT_OPTION2,
  TXT_OPTION3,
  TXT_OPTION4,
  TXT_OPTION5,
  TXT_OUTGOING_POSITION,
  TXT_PREVIEW,
  TXT_QUESTION,
  TXT_SUCCESSFULLY_SENT,
  TXT_THANK_YOU,
  TXT_UPLOAD_FILE,
  UPLOAD_TYPE_IMAGE,
  UPLOAD_TYPE_TEMPLATE,
  feedbackDefaultOptions,
  feedbackNumberOfResponseOption,
  normImagePosition
} from '../../common/v5/receiptGreetingConstants'
import {
  fontSizeAdminForm,
  inputSpace,
  smallFont
} from '../../styles/_variables'
import {
  useCallbackMultiValues,
  useCallbackWithValue
} from '../../hooks/callback'
import {
  composeWithDisplayName,
  omitProps,
  withUnmountWhenHidden
} from '../../reactcomponents/hocs'
import {
  DoubleDecks,
  HideableDiv,
  withDoubleDecks
} from '../../reactcomponents/Admin'
import {
  AreaNormSingleSelect,
  Chevron,
  SimpleNormSingleSelect,
  isInitialIntegerSelection
} from '../../reactcomponents/Dropdown'
import Input, { File } from '../../reactcomponents/Input'
import NavTabs from '../../reactcomponents/NavTabs'
import { useFeedbackUploadFileChange } from '../../containers/receiptGreeting'
import {
  CommonContent,
  Content,
  Div,
  IdValueSingleInteger,
  InputName,
  TypeAndActiveInput,
  testInvalidEmptyDataField,
  useCallbackWithUpdater,
  useInvalidEmptyDataField,
  useOnEditField
} from './receive'
import List, {
  OrgsAreasSelection,
  columns as closeColumns,
  testNoOrgOrAreaSelection,
  useEditBoolIntField,
  useNoOrgOrAreaSelection
} from './close'

export const dataInvalidation = ({ areas, name, orgs }) => {
  const emptyName = testInvalidEmptyDataField(TXT_FEEDBACK_NAME, name)
  const emptySelection = testNoOrgOrAreaSelection(orgs, areas)
  const result = []
  if (emptyName) {
    result.push(emptyName)
  }
  if (emptySelection) {
    result.push(emptySelection)
  }
  return result.join('. ')
}

export const useDataInvalidation = ({ areas, name, orgs }) => {
  const emptyName = useInvalidEmptyDataField(TXT_FEEDBACK_NAME, name)
  const emptySelection = useNoOrgOrAreaSelection(orgs, areas)
  return useMemo(
    () => {
      const result = []
      if (emptyName) {
        result.push(emptyName)
      }
      if (emptySelection) {
        result.push(emptySelection)
      }
      return result.join('. ')
    },
    [emptyName, emptySelection]
  )
}

const PlainNavTabs = omitProps(['tabId'])(NavTabs)

export default List

export const columns = closeColumns

const StyledDivChevron = styled.div`
  cursor: pointer;
  display: flex;
  font-size: ${fontSizeAdminForm};
  padding: ${inputSpace};
`
const ChevronHelper = ({ up, onClick }) => (
  <StyledDivChevron onClick={useCallbackWithValue(!up, onClick)}>
    <Chevron show={up} />
  </StyledDivChevron>
)

const optionTexts = [
  TXT_OPTION1,
  TXT_OPTION2,
  TXT_OPTION3,
  TXT_OPTION4,
  TXT_OPTION5
]

const useEditOptionField = (field, index, onOptionChange) => useCallback(
  v => {
    onOptionChange(
      options => update(options, { [index]: { [field]: { $set: v } } })
    )
  },
  [field, index, onOptionChange]
)

const DoubleDecksWithChevron = withDoubleDecks(ChevronHelper)

const StyledDoubleDecksWithChevron = styled(DoubleDecksWithChevron)`
  &.double-decks {
    .bottom {
      .main {
        > * + * {
          margin-top: ${inputSpace};
        }
      }
    }
  }
`
const StyledDotOptionDiv = styled.div`
  span:first-of-type {
    font-size: ${smallFont};
  }
  > span + span {
    margin-left: ${inputSpace};
  }
`
const DotOption = ({ index }) => (
  <StyledDotOptionDiv>
    <span>⚫</span>
    <span>{optionTexts[index]}</span>
  </StyledDotOptionDiv>
)

const ResponseOneOptionBase = ({
  action,
  answer,
  className,
  identifier,
  index,
  onChange
}) => {
  const [showIdentifier, setShowIdentifier] = useState(!!identifier)
  return (
    <Div className={className}>
      <div className='left'>
        <StyledDoubleDecksWithChevron
          onClick={setShowIdentifier}
          text={<DotOption index={index} />}
          up={showIdentifier}
        >
          <Input
            onChange={useEditOptionField('answer', index, onChange)}
            value={answer}
          />
          <DoubleDecks
            hidden={!showIdentifier}
            noRightEnd
            text={TXT_IDENTIFIER}
          >
            <Input
              onChange={useEditOptionField('identifier', index, onChange)}
              value={identifier}
            />
          </DoubleDecks>
        </StyledDoubleDecksWithChevron>
      </div>
      <div className='right'>
        <DoubleDecks text={TXT_FEEDBACK_RESPONSE}>
          <IdValueSingleInteger
            data={feedbackActions}
            onSelect={useEditOptionField('action', index, onChange)}
            selected={action}
          />
        </DoubleDecks>
      </div>
    </Div>
  )
}

const ResponseOneOption = composeWithDisplayName(
  'ResponseOneOption',
  withUnmountWhenHidden,
  memo
)(ResponseOneOptionBase)

const StyledResponseOneOption = styled(ResponseOneOption)`
  .left, .right {
    padding-left: 20px;
  }
`
const StyledDivPaddingLeft = styled(Div)`
  padding-left: 20px;
`
const ResponseOneOptionsBase = ({
  externalUrl,
  number,
  onExternalUrlChange,
  onOptionsChange,
  options
}) => (
  // eslint-disable-next-line react/jsx-fragments
  <Fragment>
    {options.map(({ action, answer, identifier }, index) => (
      <StyledResponseOneOption
        action={action}
        answer={answer}
        hidden={index >= number}
        identifier={identifier}
        index={index}
        key={index}
        onChange={onOptionsChange}
      />
    ))}
    <StyledDivPaddingLeft>
      <DoubleDecks
        helper={I('If an external page is used for the satisfaction meter, fill in the URL here.')}
        text={TXT_EXTERNAL_URL}
      >
        <Input
          onChange={onExternalUrlChange}
          value={externalUrl}
        />
      </DoubleDecks>
    </StyledDivPaddingLeft>
  </Fragment>
)

const ResponseOptions = composeWithDisplayName(
  'ResponseOneOption',
  withUnmountWhenHidden,
  memo
)(ResponseOneOptionsBase)

const useMeterDefaultState = (
  def,
  onChange,
  setOptions,
  setOptionNumber,
  onExternalUrlChange
) => {
  const [meter, setMeter] = useEditBoolIntField(def, onChange, 'default')
  const meterChange = useCallback(
    def => {
      // TODO: def is integer which is not what is expecting, should be boolean
      if (def) {
        setOptions(feedbackDefaultOptions)
        setOptionNumber(5)
        onExternalUrlChange('')
      } else {
        onExternalUrlChange(DEFAULT_EXTERNAL_URL_PREFIX)
      }
      setMeter(def)
    },
    [onExternalUrlChange, setMeter, setOptions, setOptionNumber]
  )
  return [meter, meterChange]
}

const getDefaultOptionCount = options => {
  for (let i = options.length; i > 0; i--) {
    const { answer } = options[i - 1]
    if (answer) {
      return i
    }
  }
  return 1
}

const useOptionNumber = (isNew, options, setOptions) => {
  const [
    number,
    setNumber
  ] = useState(isNew ? 5 : getDefaultOptionCount(options))
  return [
    number,
    useCallback(
      optionNumber => {
        let updater
        for (let i = optionNumber; i < 5; i++) {
          if (updater) {
            updater[i] = { answer: { $set: '' } }
          } else {
            updater = { [i]: { answer: { $set: '' } } }
          }
        }
        if (updater) {
          setOptions(options => update(options, updater))
        }
        setNumber(optionNumber)
      },
      [setNumber, setOptions]
    )
  ]
}

const EditSetting = ({
  data: {
    active,
    areas,
    content,
    default: def,
    externalUrl,
    font,
    fontColor,
    fontSize,
    fontStyle,
    name,
    options,
    orgs,
    position
  },
  isNew,
  onChange,
  onOptionNumberChange,
  onOptionsChange,
  onTypeChange,
  optionNumber,
  orgArea
}) => {
  const handleExternalUrlChange = useOnEditField(onChange, 'externalUrl')
  const [
    isDefaultMeter,
    setIsDefaultMeter
  ] = useMeterDefaultState(
    def,
    onChange,
    onOptionsChange,
    onOptionNumberChange,
    handleExternalUrlChange
  )
  return (
    // eslint-disable-next-line react/jsx-fragments
    <Fragment>
      <TypeAndActiveInput
        active={active}
        disabledReceiptType={!isNew}
        onChange={onChange}
        onTypeChange={onTypeChange}
        selected={FEEDBACKS}
      />
      <Div>
	<InputName name={name} onChange={useOnEditField(onChange, 'name')} title={TXT_FEEDBACK_NAME} />
      </Div>
      <Div>
        <OrgsAreasSelection
          areas={areas}
          onChange={onChange}
          orgArea={orgArea}
          orgs={orgs}
        />
        <div className='right'>
          <DoubleDecks text={TXT_OUTGOING_POSITION}>
            <IdValueSingleInteger
              data={feedbackOutgoingPosition}
              onSelect={useOnEditField(onChange, 'position')}
              selected={position}
            />
          </DoubleDecks>
        </div>
      </Div>
      <CommonContent onChange={onChange} title={TXT_QUESTION} value={content} />
      <Div>
        <div className='left'>
          <DoubleDecks text={TXT_FONT}>
            <IdValueSingleInteger
              data={feedbackFontFamilies}
              onSelect={useOnEditField(onChange, 'font')}
              selected={font}
            />
          </DoubleDecks>
        </div>
        <div className='right'>
          <DoubleDecks text={TXT_FONT_SIZE}>
            <IdValueSingleInteger
              data={feedbackFontSizes}
              onSelect={useOnEditField(onChange, 'fontSize')}
              selected={fontSize}
            />
          </DoubleDecks>
        </div>
      </Div>
      <Div>
        <div className='left'>
          <DoubleDecks text={TXT_FONT_COLOR}>
            <IdValueSingleInteger
              data={feedbackFontColors}
              onSelect={useOnEditField(onChange, 'fontColor')}
              selected={fontColor}
            />
          </DoubleDecks>
        </div>
        <div className='right'>
          <DoubleDecks text={TXT_FONT_STYLE}>
            <IdValueSingleInteger
              data={feedbackFontStyles}
              onSelect={useOnEditField(onChange, 'fontStyle')}
              selected={fontStyle}
            />
          </DoubleDecks>
        </div>
      </Div>
      <Div>
        <div className='left'>
          <DoubleDecks text={TXT_METER}>
            <IdValueSingleInteger
              data={customDefaultOptions}
              onSelect={setIsDefaultMeter}
              selected={isDefaultMeter}
            />
          </DoubleDecks>
        </div>
        <div className='right'>
          <DoubleDecks text={TXT_NUMBER_OF_OPTIONS}>
            <IdValueSingleInteger
              data={feedbackNumberOfResponseOption}
              disabled={isDefaultMeter}
              onSelect={onOptionNumberChange}
              selected={optionNumber}
            />
          </DoubleDecks>
        </div>
      </Div>
      <ResponseOptions
        externalUrl={externalUrl}
        hidden={isDefaultMeter}
        number={optionNumber}
        onExternalUrlChange={handleExternalUrlChange}
        onOptionsChange={onOptionsChange}
        options={options}
      />
    </Fragment>
  )
}

const pageThankYou = 0
const pageContactMe = 1
const pageRegistered = 2
const pageSent = 3

const responseProps = {
  [pageThankYou]: 'thankYouPage',
  [pageContactMe]: 'contactPage',
  [pageRegistered]: 'registeredPage',
  [pageSent]: 'contactSentPage'
}

const responseProp = responseTab => responseProps[responseTab]

const getResponseProps = (data, propName) => {
  const responseData = data[propName]
  if (responseData) {
    return responseData
  }
  return emptyObject
}

const useResponseProps = (_data, onChange, tabId) => {
  const propName = responseProp(tabId)
  return [
    getResponseProps(_data, propName),
    useCallbackMultiValues(onChange, propName, AEO_MANUAL)
  ]
}

const createMerger = data => ({ $merge: data })

const clearedFile = {
  uploadID: '',
  fileContent: '',
  fileName: '',
  url: ''
}

const clearedFileMerger = createMerger(clearedFile)

const ResponsePageBase = ({
  data: {
    content,
    fileName,
    fileContent,
    imagePosition,
    uploadType,
    url
  },
  onResponseChange,
  onPopAlert,
  tabId
}) => {
  const fileChangeUploadType = useCallback(
    file => { onResponseChange(createMerger(file)) },
    [onResponseChange]
  )
  const onFileChange = useFeedbackUploadFileChange(
    fileChangeUploadType,
    onPopAlert
  )
  return (
    // eslint-disable-next-line react/jsx-fragments
    <Fragment>
      <Content
        id='feedbacks-message'
        onChange={useCallbackWithUpdater(onResponseChange, 'content')}
        value={content}
      />
      <Div>
        <div className='left'>
          <DoubleDecks text={TXT_UPLOAD_FILE}>
            <File
              onChange={onFileChange}
              onClear={useCallback(
                () => { onResponseChange(clearedFileMerger) },
                [onResponseChange]
              )}
              name={TXT_BROWSE}
              filename={fileName}
            />
          </DoubleDecks>
        </div>
        <HideableDiv
          className='right'
          hidden={fileName && uploadType === UPLOAD_TYPE_TEMPLATE}
        >
          <DoubleDecks text={TXT_IMAGE_POSITION}>
            <SimpleNormSingleSelect
              data={normImagePosition}
              isInitializedSelection={isInitialIntegerSelection}
              onSelect={useCallbackWithUpdater(
                onResponseChange,
                'imagePosition'
              )}
              selected={imagePosition}
            />
          </DoubleDecks>
        </HideableDiv>
      </Div>
    </Fragment>
  )
}

const ResponsePage = ({
  data,
  onChange,
  onPopAlert,
  tabId
}) => {
  const [responseData, onResponseChange] = useResponseProps(
    data,
    onChange,
    tabId
  )
  return (
    <ResponsePageBase
      data={responseData}
      onChange={onChange}
      onPopAlert={onPopAlert}
      onResponseChange={onResponseChange}
      tabId={tabId}
    />
  )
}

const ContactMe = ({ data, onChange, onPopAlert, orgArea, tabId }) => {
  const [
    { byButton, byEmail, byPhone, errandContainArea, ...responseData },
    onResponseChange
  ] = useResponseProps(data, onChange, tabId)
  return (
    // eslint-disable-next-line react/jsx-fragments
    <Fragment>
      <ResponsePageBase
        data={responseData}
        onChange={onChange}
        onPopAlert={onPopAlert}
        onResponseChange={onResponseChange}
        tabId={tabId}
      />
      <Div>
        <div className='left'>
          <DoubleDecks text={TXT_CONTACT_BY_EMAIL}>
            <Input
              value={byEmail}
              onChange={useCallbackWithUpdater(onResponseChange, 'byEmail')}
            />
          </DoubleDecks>
        </div>
        <div className='right'>
          <DoubleDecks text={TXT_CONTACT_BY_PHONE}>
            <Input
              value={byPhone}
              onChange={useCallbackWithUpdater(onResponseChange, 'byPhone')}
            />
          </DoubleDecks>
        </div>
      </Div>
      <Div>
        <div className='left'>
          <DoubleDecks text={TXT_CONTACT_BUTTON}>
            <Input
              value={byButton}
              onChange={useCallbackWithUpdater(onResponseChange, 'byButton')}
            />
          </DoubleDecks>
        </div>
        <div className='right'>
          <DoubleDecks text={TXT_AREA_REPLIES}>
            <AreaNormSingleSelect
              data={orgArea}
              onSelect={useCallbackWithUpdater(
                onResponseChange,
                'errandContainArea'
              )}
              selected={errandContainArea}
            />
          </DoubleDecks>
        </div>
      </Div>
    </Fragment>
  )
}

const responseTabs = [
  {
    id: pageThankYou,
    name: TXT_THANK_YOU,
    Renderer: ResponsePage
  },
  {
    id: pageContactMe,
    name: TXT_CONTACT_ME,
    Renderer: ContactMe
  },
  {
    id: pageRegistered,
    name: TXT_ALREADY_REGISTERED,
    Renderer: ResponsePage
  },
  {
    id: pageSent,
    name: TXT_SUCCESSFULLY_SENT,
    Renderer: ResponsePage
  }
]

const MarginBottomNavTabs = styled(PlainNavTabs)`
  &.nav.nav-tabs {
    margin-bottom: ${inputSpace};
  }
`
const EditResponse = ({ onResponseTabChange, responseTab, ...props }) => (
  <MarginBottomNavTabs
    activeTab={responseTab}
    onClick={onResponseTabChange}
    tabs={responseTabs}
    {...props}
  />
)

const DivInnerHTML = ({ content }) => (
  <div
    dangerouslySetInnerHTML={useMemo(
      () => ({ __html: content }),
      [content]
    )}
  />
)

const Image = ({ className, src }) => (
  <img className={classNames('img', className)} src={src} />
)

const SpacedDiv = styled.div`
  padding: ${inputSpace};
`
const TopBottom = ({ top, bottom }) => <SpacedDiv>{top}{bottom}</SpacedDiv>

const imageTopProps = { content: 'bottom', image: 'top' }

const imageBottomProps = { content: 'top', image: 'bottom' }

const PreviewImageBase = composeWithDisplayName(
  'PreviewImage',
  memo,
  branch(
    ({ position }) => position === IMAGE_POSITION_TOP,
    renameProps(imageTopProps),
    renameProps(imageBottomProps)
  )
)(TopBottom)

const StyledImage = styled(Image)`
  max-height: 200px;
  max-width: 200px;
`
const PreviewImage = ({ content, position, url }) => (
  <PreviewImageBase
    content={<DivInnerHTML content={content} />}
    image={<StyledImage src={url} />}
    position={position}
  />
)

const useRemoveDivWrapper = content => useMemo(
  () => {
    let messageContent = content
    if (messageContent.startsWith('<div>')) {
      messageContent = messageContent.replace('<div>', '')
      const n = messageContent.lastIndexOf('</div>')
      if (n > 0) {
        messageContent = messageContent.substring(0, n)
      }
    }
    return messageContent
  },
  [content]
)

const PreviewTemplate = ({ content, fileContent }) => (
  <SpacedDiv>
    <DivInnerHTML
      content={fileContent.replace('[content]', useRemoveDivWrapper(content))}
    />
  </SpacedDiv>
)

const PreviewContent = composeWithDisplayName(
  'PreviewContent',
  memo,
  branch(
    ({ uploadType }) => uploadType === UPLOAD_TYPE_IMAGE,
    compose(
      renameProp('imagePosition', 'position'),
      renderComponent(PreviewImage)
    )
  )
)(PreviewTemplate)

const Preview = ({ data, responseTab }) => {
  const {
    content,
    file,
    fileContent,
    imagePosition,
    uploadType,
    url
  } = getResponseProps(data, responseProp(responseTab))
  return (
    <PreviewContent
      content={content}
      fileContent={file ? file.content : fileContent}
      imagePosition={imagePosition}
      uploadType={uploadType}
      url={file ? file.url : url}
    />
  )
}

const editSetting = 0
const editResponse = 1
const preview = 2

const editTabs = [
  {
    id: editSetting,
    name: TXT_FEEDBACK_SETTINGS,
    Renderer: EditSetting
  },
  {
    id: editResponse,
    name: TXT_FEEDBACK_RESPONSE,
    Renderer: EditResponse
  },
  {
    id: preview,
    name: TXT_PREVIEW,
    Renderer: Preview
  }
]

export const Edit = props => {
  const { data: { options }, isNew, onChange } = props
  const [editTab, setEditTab] = useState(editSetting)
  const [responseTab, setResponseTab] = useState(pageThankYou)
  const setOptions = useOnEditField(onChange, 'options')
  const [
    optionNumber,
    setOptionNumber
  ] = useOptionNumber(isNew, options, setOptions)
  return (
    <MarginBottomNavTabs
      activeTab={editTab}
      onClick={setEditTab}
      onOptionNumberChange={setOptionNumber}
      onOptionsChange={setOptions}
      onResponseTabChange={setResponseTab}
      optionNumber={optionNumber}
      responseTab={responseTab}
      tabs={editTabs}
      {...props}
    />
  )
}
